@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@800;900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base{
  @font-face {
    font-family: 'alliance';
    src: local('alliance'), url(./fonts/FontsFree-Net-Alliance2.ttf) format('ttf');
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(13, 84, 237) rgb(14, 0, 67);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(14, 0, 67);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(13, 84, 237);
  border-radius: 20px;
  border: 3px solid rgb(14, 0, 67);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@layer components{
  .mytheme-bg{
    background-image:radial-gradient(circle at left center, #041a3f,#040d21);
  }
}
